html {
    
}

body {
  background: url('./assets/grey_pattern.svg') #fff repeat;
  /* background: rgba(255, 255, 255, 0.9); */
  position: relative;
}

a, p, li, h1 {
  font-family: 'Bebas Neue', sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 36px;
}

ul {
  margin: 0;
  padding: 0;
  display: flex;
  gap: 56px
}

li {
  list-style-type: none;
}

.black {
  color: #121213;
}

.white {
  color: #F9F8F8;
}

.nav {
  /* width: 64%; */
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.header .logo {
  display: block;
  width: 190px;}

.mobile-view {display: none;}

.nav__link-button {
  font-size: 24px;
  line-height: 17px;
  color: #F9F8F8;
  border: 2px solid #F9F8F8;
  background: #121213;
  border-radius: 12px;
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 54px;
  padding-right: 54px;
  transition: all 0.3s ease 0s;
  white-space: nowrap;
}

.nav__link-button:hover {
  border-radius: 12px;
  border: 2px solid #121213;
  background: #F9F8F8;
  color: #121213;
  transition: all 0.3s ease 0s;
}

.section_header {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items:center;
  font-size: 130px;
}

.section__1 .section_header {
    background: #D9D9D9;
}

.section__1 {
  height: 1001px;
}

.section__1 .background {
  height: 1101px;
  background: url('./assets/tyke.png') 50% 0/cover no-repeat;
  display: flex;
  justify-content: flex-end;
  align-items:center;
  flex-direction: column;
}

.section__1 .block_bord {
  background: url('./assets/cf6c15828a024b5c1166de4e07f82d98.png') repeat, url('./assets/grey_pattern.svg') #fff 0px 100px repeat;
  height: 100px;
  width: 100%;
  border-radius: 100px 100px 0px 0px;
  overflow: hidden;
}

.block_bord div {
  border-radius: 100px 100px 0px 0px;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(20px);
}

.section__5 div p,
.section__1 div p {
  text-align: center;
  word-wrap: break-word;
  width: 100%;
  display: block;
  margin-bottom: 200px;
  padding: 24px 32px 24px 32px;
  border-radius: 16px;
  border: 2px;
  box-shadow: 8px 8px 0px 0px #121213;
  background: #F9F8F8;
  font-family: 'Helvetica Neue';
  font-size: 24px;
  font-weight: 500;
  line-height: 21.47px;
}

.section__1 div p {
  width: 80%;
}

.section__5 div p {
  width: 100%;
  margin: 50px 0;
}



.section__2 {
  border-radius: 100px 100px 0px 0px;
  overflow: hidden;
  background: url('./assets/cf6c15828a024b5c1166de4e07f82d98.png') repeat, url('./assets/black_pattern.svg') #000 repeat; 
}

.with-img {
  padding-top: 50px;
  display: flex;
  justify-content: center;
}

.section__2 .section_header::after {
  content: '';
  width: 97px;
}

.section__4 .lines,
.section__2 .lines {
  position: relative;
  top: -70px;
}

.card-list {
  padding: 0 160px 200px 160px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

.card-list li {
  display: flex;
  flex-direction: column;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 360px;
  height: 360px;
  background: linear-gradient(220deg, transparent 45px, rgba(35, 35, 37, 0.65) 0) top right;
  border-radius: 12px;
  padding: 24px;
  gap: 12px;
  backdrop-filter: blur(20px);
}

.card img {
  width: 160px;
}

.card-text p {
  font-family: 'Helvetica Neue';
  font-size: 32px;
  font-weight: 400;
  line-height: 38.18px;
  color: #A6A6A6;
}

.section__3_wrap {
  background: #000;
}

.section__3 {
  border-radius: 100px 100px 0px 0px;
  padding:  0 160px 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  border-radius: 100px 100px 0px 0px;
  /* background: rgba(255, 255, 255, 0.5); */
  background: url('./assets/grey_pattern.svg') #fff repeat;
}

/* .section__3 h1 {
  width: 100%;
  padding: 236px 0px;
  border-radius: 56px;
  height: auto;
} */

/* slider */
.section__4 {
  border-radius: 100px 100px 0px 0px;
  overflow: hidden;
  background: url('./assets/cf6c15828a024b5c1166de4e07f82d98.png') repeat, url('./assets/black_pattern.svg') #000 repeat; 
}

.section__5 .section_header::before,
.section__3 .section_header::before {
  content: '';
  width: 97px;
}

.section__5 .lines,
.section__3 .lines {
  transform: rotate(100deg);
  position: relative;
  top: -80px;
}

.slider__container {
    padding: 0 160px 200px 160px;
}
.slide {
  display: none;
  max-height: 0px;
  min-height: 0px;
}

.slide.active {
  display: block;
  /* max-height: 600px; */
  max-height: 336px;
}

.control {
  color: #A6A6A6;
  font-family: "Helvetica Neue";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
}

.control.active {
  color: #F9F8F8;
  font-weight: 700;
}

.slider {
  /* display: flex;
  flex-direction: column; */
  width: 100%;
  /* align-items: center; */
}
.controls {
  text-align: center;
  margin: 0 auto;
  width: 40%;
  display: flex;
  justify-content: space-around;
  /* justify-content: center; */
  position: relative;
  /* gap: 20px; */
  top: 30px;
  z-index: 8;
}

.slide__content {
  width: 100%;
  max-height: 440px;
  min-height: 400px;
  display: flex;
  justify-content: space-around;
  background: url('./assets/frame.png') 0 0/100% 100% no-repeat;
}

.img__container {
  /* width: 700px; */
  width: 50%;
  position: relative;
	overflow: hidden;
  padding: 32px 0 0 0px;
      display: flex;
    align-items: end;
    justify-content: flex-end;

}

.slide__content img {
    max-width: 100%;
    height: auto;
    max-height: 350px;
    /* height: 100%;
    object-fit: cover; */
}

.slide__content-text {
  width: 40%;
  display: flex;
  flex-direction: column;
  padding: 100px 50px 26px 0px;
}

.slide__content-text .nav__link-button {
  margin-top: 40px;
  width: fit-content;
}

.slide__content-text h3 {
  padding: 0;
  margin: 0 0 24px 0;
  color: #121213;
  font-family: "Helvetica Neue";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.slide__content-text ul {
  flex-direction: column;
  gap: 8px;
  padding-left: 15px;
}

.slide__content-text li {
  list-style-type: disc;
  
}

.slide__content-text li,
.slide__content-text p {
  color: #121213;
font-family: "Helvetica Neue";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.section__5 .lines img {
  fill: #121213;
}

.section__5-wrap {
  background: #000;
}
.sec5head {
  margin-top: 100px;
  margin-bottom: 100px;
  color: #121213;
text-align: center;
font-family: "Bebas Neue";
font-size: 130px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.section__5 {
  padding: 50px 160px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 100px 100px 0px 0px;
  /* background: rgba(255, 255, 255, 0.5); */
  /* background: url('./assets/grey_pattern.svg') #fff repeat; */
background: url('./assets/grey_pattern.svg') #fff repeat;
}

.section__4 .section_header::after {
  content: '';
  width: 97px;
}
.section__5 .section_header {
  text-align: center;
  margin: 150px 0 100px;
  line-height: normal;
}

.horizontal,
.section__5 .hollywood {
  width: 100%;
}

.nav_footer {
  gap: 24px;
  padding: 24px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background: url('./assets/grey_pattern.svg') #fff repeat; */
  background: transparent;
}


.open{
    display: flex !important;
    justify-content: center;
    gap: 100px
}

.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 100px;
  padding: 24px 160px;
  background: url('./assets/grey_pattern.svg') #fff repeat;
}

.burger{
    display: none;
    position: relative;
    z-index: 50;
    align-items: center;
    justify-content: flex-end;
    width: 30px;
    height: 18px;
}

.burger span{
    height: 2px;
    width: 100%;
    transform: scale(1);
    background-color: #121213;
}

.burger::before, .burger::after{
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: #121213;
    transition: all 0.3s ease 0s;
}

.burger::before { top: 0 }

.burger::after { bottom: 0 }


/* Добавляем класс active для анимации иконки бургера */
html.burger.active { overflow: none;}

.burger.active span { transform: scale(0) }

.burger.active::before{
    top: 50%;
    transform: rotate(-45deg) translate(0, -50%);
}

.burger.active::after{
    bottom: 50%;
    transform: rotate(45deg) translate(0, 50%);
}

@keyframes burgerAnimation {
    from {opacity: 0}
    to {opacity: 1}
}

.dropdown-wrapper {
  display: none;
}

  .flex {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  @media only screen and (min-width: 641px) and (max-width: 1524px)  {
 
    .control {
    font-size: 20px;
  }
  }

@media only screen and (min-width: 641px) and (max-width: 1024px)  {
  a, p, li {
    font-size: 24px;
  }

  .sec5head {
    font-size: 70px;
      margin-top: 60px;
  margin-bottom: 60px;

  }

  .mobile-view {display: block;}
  .desctop-view {display: none;}
  .nav_footer,
  .nav {
    padding: 24px 40px;
  }

  .section_header {
    height: 70px;
    font-size: 70px;
    line-height: normal;
  }

  .with-img {
    margin: 100px 0 60px 0;
  }

  .section__1 {
    height: 741px;
}

.section__1 div p {
    margin-bottom: 140px;
  }

  .section__1 .background {
    height: 841px;
    background: url('./assets/tyke.png') 50% 0/auto 100% no-repeat;
  }

  .card-list {
    padding: 0 40px 100px 40px;
  }

  .section__3 {
    padding: 0 40px 150px;
  }

  /* .section__3 h1 {
    padding: 182px 0;
  } */

  .slider__container {
    padding: 0 40px 150px 40px;
  }
  .slide__content {
    min-height: 350px;
    background: url('./assets/frame1024.png') 0 0 / 100% 100% no-repeat;
  }

  .img__container {
    display: flex;
    align-items: end;
    justify-content: flex-end;
    /* width: 420px; */
    padding: 32px 0 0 0px;
  }

  .slide__content img {
    width: 100%;
    /* min-height: 300px; */
    height: auto;
    /* object-fit: cover; */
}

  .slide__content-text {
    width: 50%;
    padding: 70px 40px 26px 20px;
  }

  .section__5 {
    padding: 50px 40px 0;
  }

  .section__5 .with-img {
    margin-top: 40px;
  }

  .lines {
    width: 56px;
  }
  
  .section__3 .lines,
  .section__4 .lines,
  .section__5 .lines,
  .section__2 .lines {
    position: relative;
    top: -40px;
  }

  .section__4 .lines {
    top: -50px;
  }

  .section__4 .section_header::after,
  .section__2 .section_header::after {
    content: '';
    width: 56px;
  }

  .with-img {
    padding: 0;
  }

  .burger { display: flex !important; }

    .nav{
        display: none;
        flex-direction: column;
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0; bottom: 0; left: 0; right: 0;
        z-index: 50;
        overflow-y: auto;
        padding: 50px 40px;
        background-color: #fff;
        animation: burgerAnimation 0.4s;
    }

    .nav ul{
        flex-direction: column;
        row-gap: 30px;
    }

    .header {
      padding: 20px;
    }

    ul {gap: 32px;}

    .nav__link-button {
      padding-left: 20px;
      padding-right: 20px;
    }
}

@media only screen and (max-width: 640px) and (min-width: 319px)  {
  a, p, li {
    font-size: 24px;
  }
    .mobile-view {display: block;}
  .desctop-view {display: none;}

  .sec5head {
    font-size: 40px;
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .nav {
    padding: 20px;
  }

  .nav_footer {
    flex-direction: column;
    padding: 20px;
    gap: 24px;
  }

  .nav_footer ul {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  .slide__content-text .nav__link-button {
    width: 100%;
    text-align: center;
  }

  .nav_footer .logo {
    display: none;
  }

  .section_header {
    font-size: 40px;
    height: 150px;
  }

  .section__1 div p {
    margin-bottom: 140px;
  }

.section__5 div p,
  .section__1 div p {
    font-size: 16px;
  }


  .section__1 {
    height: 600px;
}

  .section__1 .background {
    height: 700px;
    background-size: auto 100%;
    background-position-x: -150px;
    padding: 0 20px;
}

  .control {
    font-size: 16px;
  }

  .card-list {
    padding: 20px 20px 100px;
  }

  .card {
    min-width: 280px;
    height: 300px;
  }

  .card img {
    width: 100px;
  }

  .card p {
    font-size: 24px;
  }

  .section__4 .section_header,
   .section__2 .section_header {
    height: fit-content;
    margin: 100px 0 40px 0;
   }

  .section__2,
  .section__3,
  .section__4,
  .section__5 {
    border-radius: 44px 44px 0px 0px;
  }

  .section__3 {
    padding: 0 20px 100px;
  }

  /* .section__3 h1 {
    width: 100%;
    padding: 180px 0px;
  } */

  .section__4 {
    padding-bottom: 45% ;
  }

  .slider__container {
    padding: 00px 20px 100px;
  }

  .slide__content {
    display: flex;
    flex-direction: column;
    align-items:center;
    max-height: fit-content;
    min-height: fit-content;
    margin-bottom: 200px;
    background: url('./assets/frame640.png') 0 0 / 100% 100% no-repeat;
    border-radius: 0px 0px 44px 44px;
    overflow: hidden;
  }

  .img__container {
    width: 100%;
    order: 2;
  }

  .slide.active {
  min-height: 400px;
}

  .slide__content img { 
    width: 100%;
    height: auto;
  }

  .slide__content-text {
    min-height: 320px;
    width: 100%;
    padding: 80px 20px 40px;
  }

  .section__5 {
    padding: 50px 20px 0px;
}

  .lines {
    width: 39px;
  }
  
  .section__3 .lines,
  .section__4 .lines,
  .section__5 .lines {
    position: relative;
    top: -30px;
  }

  .section__2 .lines {
    position: relative;
    top: 0px;
  }

  .section__4 .lines {
    top: 0px;
  }

  .section__2 .section_header::after {
    content: '';
    width: 56px;
  }

  .section__5 .section_header {
    margin: 0 0 20px;
  }

  .section__5 .section_header::before,
  .section__3 .section_header::before,
  .section__4 .section_header::after {
    width: 38px;
  }

  .with-img {
    padding: 0;
  }

  .burger { display: flex !important; }

    .nav{
        display: none;
        flex-direction: column;
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0; bottom: 0; left: 0; right: 0;
        z-index: 50;
        overflow-y: auto;
        padding: 50px 40px;
        background-color: #fff;
        animation: burgerAnimation 0.4s;
    }

    .nav ul{
        flex-direction: column;
        row-gap: 30px;
    }

    .header{
      padding: 20px;
    }

    .dropdown-wrapper {
      display: flex;
      justify-content: center;
      position: relative;
      top: 30px;
      z-index: 8;

    }

    .Dropdown-root {
      
      width: 111px;
    }

    .Dropdown-root .drop-ctrl {
      border: 0;
      background-color: transparent !important;
      color: #F9F8F8;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .Dropdown-placeholder {
      white-space: nowrap;
      font-family: "Helvetica Neue";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    
    .Dropdown-arrow {
      border-width: 10px 10px 0 !important;
    }

    .is-open .Dropdown-arrow {
      border-width: 0 10px 10px !important;
    }

    .controls {
      display: none;
    }

}

